<template>
    <v-container fluid>
        <v-img max-height="400" src="/img/connecting.png">
            <v-row align="center" class="lightbox white--text pa-12 fill-height">
                <v-col>
                    <div class="display-2 text-center">Save time, your deals a click away
                        "Click. Quote. Trade"</div>
                </v-col>
            </v-row>
        </v-img>

        <v-divider> </v-divider>

        <div>
            <h2>Star Categories:</h2>
            <div class="uk-position-relative uk-visible-toggle uk-light store-star-products" tabindex="-1" uk-slider>

                <ul class="uk-slider-items uk-grid">
                    <li v-for="category of star_categories">
                        <div class="uk-card uk-card-default uk-height-1-1 uk-width-medium" @click="navToCategory(category)">
                            <div class="uk-card-media-top">
                                <img :src="category.image" alt="">
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title noselect">{{category.name}}</h3>
                            </div>
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>


        <v-divider> </v-divider>

        <div>
            <h2>Recommended for you:</h2>
            <v-layout row wrap>
                <store-products :products="usage_products" :user="user"></store-products>
            </v-layout>
        </div>
        <v-divider> </v-divider>
    </v-container>
</template>

<script>
    export default {
        name: "Store",
        props: {
            categories: Array
        },
        data: () => ({
            recommended_products_ids: [],
            recommended_products: [],
            user:{},
            star_categories: [
                {
                    id:118,
                    name: 'Baby and Toddler',
                    link: '/search/118/undefined',
                    image: '/img/category images/baby and todler.jpg'
                },
                {
                    id:73,
                    name: 'Bed and Bath',
                    link: '/search/73/undefined',
                    image: '/img/category images/bed and bath.jpg'
                },
                {
                    id:392,
                    name: 'Christmas',
                    link: '/search/392/undefined',
                    image: '/img/category images/christmas.jpg'
                },
            ],
            usage_products_ids: [],
            usage_products: [],
            recommended_categories: [],

            products: [],
            title: "Store"
        }),
        created () {
            this.$emit('get-cart-products');
            this.user = JSON.parse(localStorage.getItem('user'));
            this.$http.get('/store/search/?limit=8')
                 .then( result => {
                     console.log(result.data.results);
                     this.usage_products = result.data.results;
                });
        },
        methods : {
            navToProduct: function (product){
                this.$emit('navigate-to-product', product.erp_item_number);
            },
            navToCategory: function(category){

                this.$emit('navigate-to-category', category.id);
            }
        }
    }
</script>

<style scoped type="scss">
    .store-moving-header{
        border-radius: 10px 10px 0 0;
        margin-bottom: -160px;
        margin-right: -50px;
        margin-left: -50px;
    }

    .store-star-products{
        height:260px;
    }

    .category-widget-background{
        background-color: #0d47a1;
        border-radius: 5px;
    }
</style>
